<template>
  <v-row no-gutters v-if="product" class="product-detail first-block mb-7">
    <v-col cols="12" sm="6" class="py-0 py-sm-3">
      <v-card flat v-if="selectedImage">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-img
              class="vendorIcon"
              v-bind="attrs"
              v-on="on"
              v-if="product.vendor.url"
              :src="product.vendor.url"
              height="auto"
              max-width="64"
              contain
              :alt="product.vendor.name"
            />
          </template>
          <span>{{ product.vendor.name }}</span>
        </v-tooltip>
        <ProductImages
          :images="product.media"
          :name="product.name"
          :key="product.productId"
        />
      </v-card>
    </v-col>

    <v-col
      cols="12"
      sm="6"
      class="py-0 py-sm-3 px-1 default--text"
      :class="{ 'text-center': $vuetify.breakpoint.xs }"
    >
      <div class="d-flex promo-wrapper">
        <ProductPromo :product="product" />
      </div>
      <product-name :product="product" :full="true" :classes="'text-left'" />
      <div class="d-flex align-center">
        <div class="mt-4">
          <ProductAcqLimit :product="product" :isPDP="true" />
        </div>
      </div>

      <v-row
        no-gutters
        class="mb-4 mb-sm-0 mb-md-14 mt-3"
        justify="center"
        justify-sm="start"
      >
        <ProductClass
          :pClass="pClass"
          :width="27"
          :height="27"
          v-for="pClass in productClasses"
          :key="pClass.productClassId"
        />
      </v-row>

      <div
        class="d-flex flex-column align-start justify-space-between mb-3"
        style="max-width: 300px"
      >
        <div class="w-100">
          <ProductPrice :product="product" :showStandard="true" />
        </div>
        <div class="d-flex mt-2 w-100" v-if="product.available > 0">
          <div
            class="qty-wrap rounded-md"
            :class="{ 'not-empty': quantity > 0 }"
            transition="fab-transition"
          >
            <a
              role="button"
              class="minus"
              @click.stop.prevent="minus"
              @mousedown.stop
              v-ripple
            >
              <v-icon>$minus</v-icon>
            </a>
            <div class="val-cnt">
              <span class="val">{{ quantity }} {{ unit }}</span>
              <span class="small ml-1">{{ quantityPerUnit }}</span>
            </div>
            <a
              role="button"
              class="plus"
              @click.stop.prevent="plus"
              @mousedown.stop
              v-ripple
            >
              <v-icon>$plus</v-icon>
            </a>
          </div>
          <div
            v-if="
              product.productInfos.ENABLE_ACQ_BOX == 'true' &&
                product.logisticPackagingItems
            "
            class="logistic-packagin-items secondary lighten-3 d-flex align-center justify- white--text rounded px-2 mr-1"
          >
            <span class="text-uppercase text-right">
              <strong>+{{ product.logisticPackagingItems }}</strong>
              <br />
              {{ $t("product.pieces") }}
            </span>
            <v-icon class="ml-1" color="white">$box</v-icon>
          </div>
          <v-btn
            color="secondary"
            class="add-to-cart"
            elevation="0"
            @click.stop.prevent="addToCart(quantity)"
          >
            <v-icon>$cart</v-icon></v-btn
          >
        </div>
        <div
          v-else
          class="not-available error--text text-center text-h5 font-weight-bold mt-2"
        >
          {{ $t("product.notAvailable") }}
        </div>
      </div>

      <v-divider></v-divider>

      <div
        class="add-to-list d-flex align-center grey--text justify-space-between justify-md-start"
      >
        <div class="d-flex align-center" @click="addToFavorites">
          <v-btn icon>
            <v-icon v-if="highlight" color="primary">$heartfull</v-icon>
            <v-icon color="grey base" v-else>$heartfull</v-icon>
          </v-btn>

          <span class="text-caption">{{ $t("product.addToList") }}</span>
        </div>
      </div>
    </v-col>
    <div class="pt-5 pb-3 px-2 px-sm-5 text-caption secondary--text">
      <i18n path="footer.info" tag="span" class="copyright text-center">
        <router-link
          class="secondary--text"
          :to="{
            name: 'Page',
            params: { pageName: 'contatti' }
          }"
        >
          {{ $t("footer.infoLink") }}
        </router-link>
      </i18n>
    </div>
  </v-row>
</template>
<style lang="scss">
.product-detail {
  &.first-block {
    background-color: $white;
    border-radius: $border-radius-root;
  }
  /*
  .max-acq {
    width: 36px;
    max-height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    span {
      text-transform: uppercase;
      padding: 2px 2px;
      color: #ffffff;
      line-height: 1;
      background-color: $secondary;
      font-size: 10px;
      font-weight: bold;
      display: flex;
      text-align: center;
      border-radius: $border-radius-root;
      justify-content: center;
      align-items: center;
      &.category-limit {
        background-color: var(--v-secondary-lighten3);
      }
    }
  }*/
  .description {
    text-align: left;
    align-items: left;
    .name {
      font-size: 22px;
      color: $text-color;
    }
  }
  .small {
    font-size: 10px;
  }
  .vendorIcon {
    position: absolute;
    right: 6px;
    top: -18px;
    z-index: 6;
  }
  .qty-wrap {
    width: auto;
    .plus {
      width: auto;
      @media #{map-get($display-breakpoints, 'xs-only')} {
        width: 37px;
        min-width: auto;
      }
    }
  }

  .promo-wrapper {
    height: $promo-box-height;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      height: auto;
    }
  }
  // .add-to-list{
  //   font-size: 12px;
  // }
}
</style>
<script>
import ProductPrice from "@/components/product/ProductPrice.vue";
import ProductPromo from "@/components/product/ProductPromo.vue";
import ProductClass from "@/components/product/ProductClass.vue";
import ProductImages from "@/components/product/ProductImages.vue";
import ProductAcqLimit from "./ProductAcqLimit.vue";
import ProductName from "./ProductName.vue";

import productMixin from "~/mixins/product";
import { mapState, mapGetters } from "vuex";

import get from "lodash/get";

export default {
  name: "ProductDetail",
  props: ["selectedImage", "product"],
  components: {
    ProductPrice,
    ProductPromo,
    ProductClass,
    ProductImages,
    ProductAcqLimit,
    ProductName
  },
  mixins: [productMixin],
  data() {
    return {
      imageDetailDialog: false
    };
  },
  computed: {
    ...mapGetters({
      getItemByProductId: "cart/getItemByProductId"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    item() {
      return null;
    },
    productClasses() {
      if (global.config.filterProductClasses && this.product.productClasses) {
        return this.product.productClasses.filter(
          p =>
            global.config.filterProductClasses.includes(p.productClassId) &&
            p.productClassTypeId == 2 &&
            p.productClassGroupId != 1
        );
      } else {
        return this.product.productClasses.filter(
          p => p.productClassTypeId == 2 && p.productClassGroupId != 1
        );
      }
    },
    productClassPromo() {
      return this.product.productClasses.filter(
        p => p.productClassTypeId == 2 && p.productClassGroupId == 1
      );
    },
    promoObj() {
      //  promo sottocosto
      let underPriceClass = get(this.product, "productInfos.UNDERPRICE");
      if (underPriceClass) {
        return {
          promoClasses: "is-underprice red_box",
          promoText: this.$t("promo.underPrice")
        };
      }

      // warehousePromo
      let whPromoCssClass = get(this.product, "warehousePromo.view.cssClass");
      if (whPromoCssClass) {
        let whPromoText = get(this.product, "warehousePromo.view.body");
        return {
          promoClasses: "has-promo " + whPromoCssClass,
          promoText: whPromoText
        };
      }

      // "promo" from product class
      if (this.productClassPromo && this.productClassPromo.length > 0) {
        // should add description field of every product promo class
        // let temp = [...this.productClasses.map(p => p.description)];
        return {
          promoClasses: this.productClassPromo[0].description,
          promoText: this.productClassPromo[0].name
        };
      }

      // "promo" from newProduct
      if (this.product.newProduct) {
        return {
          promoClasses: "new-product is-new yellow_box",
          promoText: this.$t("promo.newProduct")
        };
      }

      // default no promo
      return null;
    }
  },
  methods: {
    selectImage(media) {
      this.$emit("selectImage", media);
    },
    openImageDialog() {
      this.imageDetailDialog = true;
    }
  }
};
</script>
