<template>
  <v-container>
    <Breadcrumb :items="productBreadcrumb" />

    <ProductDetail
      v-if="product.productId"
      :product="product"
      :selectedImage="selectedImage"
      :key="'product_detail_' + key"
      @selectImage="selectImage"
    />

    <ProductDescription
      v-if="product.productId"
      :product="product"
      :key="'product_description_' + key"
      :productMetadata="product.metaData"
      :ingredientList="product.ingredientList"
    />

    <div class="grey lighten-1">
      <ProductListSlider
        v-if="product.productId"
        :layout="220"
        :limit="12"
        :autoplay="true"
        :productId="product.productId"
        :key="product.productId + '_1'"
        :paginationClass="'swiper-pagination-interesse'"
        position="product_correlati"
      >
        <template #title>
          <div class="proposal-title-wrapper secondary lighten-1">
            <h2 class="proposal-title text-uppercase mb-3">
              Potrebbe interessarti anche
            </h2>
          </div>
        </template>
      </ProductListSlider>
    </div>
    <!-- <component
      v-if="footer && footer.length > 0"
      :is="category.metaData.template_model.FOOTER_MODE"
      :title="category.metaData.template_model.FOOTER_TITLE"
      :proposals="footer"
      paginationClass="pagination-FOOTER"
      cols="auto"
      sm="auto"
      md="auto"
      lg="auto"
    /> -->
    <ProductListSlider
      v-if="product.productId"
      :layout="223"
      :limit="12"
      :productId="product.productId"
      :key="product.productId + '_2'"
      :paginationClass="'swiper-pagination-suggeriti'"
      position="product_suggeriti"
      cols="auto"
      sm="auto"
      md="auto"
      lg="auto"
    >
      <template #title>
        <div class="proposal-title-wrapper secondary lighten-1">
          <h2 class="proposal-title text-uppercase mb-3">
            Spesso acquistati <strong>insieme</strong>
          </h2>
        </div>
      </template>
    </ProductListSlider>
  </v-container>
</template>

<script>
import join from "lodash/join";
import compact from "lodash/compact";
import get from "lodash/get";
import clone from "lodash/clone";

import ProductDescription from "@/components/product/ProductDescription.vue";
import ProductDetail from "@/components/product/ProductDetail.vue";
import ProductListSlider from "@/components/product/ProductListSlider.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";

import categoryMixin from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";
// import { mapProposal } from "~/service/ebsn.js";

import ProductService from "~/service/productService";

export default {
  name: "Product",
  mixins: [categoryMixin, deliveryReactive],
  jsonld() {
    if (this.product.productId) {
      let images = this.product.media.map(item => item.medium);
      return {
        "@context": "https://schema.org/",
        "@type": "Product",
        name: this.product.name,
        image: images,
        description: this.product.description,
        sku: this.product.codInt,
        mpn: this.product.codInt,
        brand: {
          "@type": "Brand",
          name: this.product.shortDescr
        },
        // review: {
        //   "@type": "Review",
        //   reviewRating: {
        //     "@type": "Rating",
        //     ratingValue: "4",
        //     bestRating: "5"
        //   },
        //   author: {
        //     "@type": "Organization",
        //     name: "Tosano"
        //   }
        // },
        // aggregateRating: {
        //   "@type": "AggregateRating",
        //   ratingValue: "4.4",
        //   reviewCount: "89"
        // },
        offers: {
          "@type": "Offer",
          url: process.env.VUE_APP_EBSN_URL + "/" + this.product.slug,
          priceCurrency: "EUR",
          price: this.product.price ? this.product.price : 0,
          priceValidUntil: "2050-12-31", // NON HO UNA DATA VALIDA DI PREZZO
          itemCondition: "https://schema.org/NewCondition",
          availability:
            this.product.available >= 0
              ? "https://schema.org/InStock"
              : "https://schema.org/OutOfStock"
        }
      };
    }
  },
  data() {
    return {
      product: {
        productId: null,
        name: "",
        shortDescr: "",
        description: ""
      },
      showNoProductCard: false,
      selectedImage: null,
      key: 1
    };
  },
  components: {
    ProductDescription,
    ProductDetail,
    Breadcrumb,
    ProductListSlider
  },
  computed: {
    getFullName() {
      return join(
        compact([
          this.product.name,
          this.product.shortDescr,
          this.product.description
        ]),
        " "
      );
    },
    // ...mapProposal({
    //   header: "header",
    //   footer: "footer"
    // }),
    productBreadcrumb() {
      let b = clone(this.breadcrumb);
      if (this.product) {
        b.push({
          to: {
            name: "Product",
            params: {
              volantino: this.product.name
            }
          },
          text: this.product.name,
          exact: true
        });
      }
      return b;
    }
  },
  methods: {
    async getProduct() {
      var data = await ProductService.getProductBySlug(this.slug);
      if (data) {
        this.product = data;
        if (this.product.categoryId) {
          this.setCategory(this.product.categoryId);
        }

        if (this.product && this.product.media && this.product.media.length) {
          this.selectImage(this.product.media[0]);
        }
      } else {
        this.showNoProductCard = true;
      }
    },
    async setCategory(categoryId) {
      this.$store.dispatch("category/setCategory", {
        slug: categoryId,
        reset: false
      });
    },
    async selectImage(image) {
      this.selectedImage = image;
      this.selectedImage.thumb = image.medium;
    },
    async reload() {
      //this.$store.dispatch("category/resetFilters");
      //let slug = split(this.category.slug, "@")[0];
      //await this.setCategory(slug);
      await this.getProduct();
      this.key += 1;
    }
  },
  created() {
    this.slug = this.$route.params.slug;
    this.showNoProductCard = false;
    this.getProduct();
  },
  watch: {
    async $route(to) {
      if (to.params.slug != this.slug) {
        this.slug = to.params.slug;
        await this.getProduct();
      }
    }
  },
  metaInfo() {
    return {
      title: get(
        this.product,
        "metaData.product_seo.SEO_TITLE",
        this.getFullName
      ),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(
            this.product,
            "metaData.product_seo.SEO_DESCRIPTION",
            this.getFullName +
              " online. " +
              this.category.name +
              " in offerta e promozione su Tosano Spesa Online con consegna a casa, ritiro in negozio e ritiro locker."
          )
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: get(this.product, "metaData.product_seo.SEO_KEYWORDS")
        }
      ]
    };
  }
};
</script>
