<template>
  <div class="product-description" v-if="tabs.length > 0">
    <div
      v-if="!$vuetify.breakpoint.xs"
      class="product-description_tabs mt-5 mb-7"
    >
      <v-tabs v-model="selectedTab" slider-size="0">
        <v-tab
          v-for="(tab, idx) in tabs"
          :key="idx"
          class="text-h5 text-uppercase accent--text font-weight-bold"
        >
          <span>
            {{ tab.title }}
          </span>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="selectedTab">
        <v-tab-item v-for="tab in tabs" :key="tab.title" :class="tab.cssClass">
          <div
            v-for="item in tab.items"
            :key="item.title"
            :class="item.cssClass"
          >
            <div
              class="text-h5 text-uppercase font-weight-bold"
              v-if="item.title"
            >
              {{ item.title }}
            </div>
            <div class="content" v-for="path in item.paths" :key="path.key">
              <component
                :is="path.template || 'DefaultTpl'"
                :content="path.content"
                :append="path.append"
              />
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <v-expansion-panels
      v-else
      class="my-6"
      accordion
      flat
      tile
      :focusable="selectedTab"
    >
      <v-expansion-panel
        v-for="tab in tabs"
        :key="tab.title"
        :class="tab.cssClass"
      >
        <v-expansion-panel-header class="pa-3 px-sm-3">
          <template v-slot:actions>
            <v-icon color="accent">$expand</v-icon>
          </template>
          <div class="text-body-2 font-weight-bold" v-html="tab.title" />
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mt-2">
          <div
            v-for="(item, index) in tab.items"
            :key="item.title"
            :class="(index > tab.items.length - 1 ? 'pb-4' : '', item.cssClass)"
          >
            <h4 v-if="item.title">{{ item.title }}</h4>
            <div v-for="path in item.paths" :key="path.key">
              <component
                :is="path.template || 'DefaultTpl'"
                :content="path.content"
                :append="path.append"
              />
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import {
  DefaultTpl,
  LabelsTpl,
  ItemsMapTpl,
  TableTpl,
  NameAndValueTpl,
  ValueTpl,
  WarningsTpl,
  ListTpl
} from "./templates";

import get from "lodash/get";
import tabsConfig from "/public/product/descriptionTabsConfig.json";
import kebabCase from "lodash/kebabCase";
export default {
  name: "ProductDescription",
  /* eslint-disable vue/no-unused-components */
  components: {
    DefaultTpl,
    LabelsTpl,
    ItemsMapTpl,
    TableTpl,
    NameAndValueTpl,
    ValueTpl,
    WarningsTpl,
    ListTpl
  },
  data() {
    return {
      selectedTab: null,
      productDescriptionTabs: tabsConfig
    };
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    productMetadata: {
      type: Object
    },
    ingredientList: {
      type: Object
    }
  },
  computed: {
    tabs() {
      let tabs = [];
      this.productDescriptionTabs.forEach(tabMeta => {
        let items = [];
        tabMeta.items.forEach(parMeta => {
          let paths = [];
          parMeta.paths.forEach(pathMeta => {
            let content = get(this.product, pathMeta.data || pathMeta);
            let append = pathMeta.append;
            // check if content is empty
            if (content && content !== "<p></p>") {
              // check if table has empty cells
              if (pathMeta.template === "TableTpl") {
                let cells = JSON.parse(content).cells;
                console.log(cells);
                let hasContent = cells.find(c => {
                  return c !== null;
                });
                if (!hasContent) {
                  return;
                }
              }
              if (pathMeta.template === "ValueTpl") {
                let value = JSON.parse(content).value;
                if (!value) {
                  return;
                }
              }
              paths.push({
                key: pathMeta.data || pathMeta,
                content: content,
                template: pathMeta.template,
                append: append
              });
            }
          });
          if (paths.length > 0) {
            items.push({
              title: parMeta.title,
              paths: paths,
              cssClass: get(parMeta, "cssClass", kebabCase(parMeta.title))
            });
          }
        });
        if (items.length > 0) {
          tabs.push({
            title: tabMeta.title,
            items: items,
            cssClass: get(tabMeta, "cssClass", kebabCase(tabMeta.title))
          });
        }
      });

      // additional text at Ingredients tab end
      let ingredientsTab = tabs.find(t => {
        return t.title === "Ingredienti";
      });
      if (ingredientsTab) {
        ingredientsTab.items.push({
          paths: [
            { content: this.$t("productDescription.ingredientsAdditionalInfo") }
          ]
        });
      }
      return tabs;
    }
  }
};
</script>

<style lang="scss">
.product-description {
  .v-tabs {
    position: relative;
    top: 1px;
    z-index: 1;
    background-color: var(--v-accent-lighten2);
    .v-slide-group__wrapper {
      background-color: var(--v-accent-lighten2);
    }
    .v-item-group {
      height: 46px;
    }
    .v-tab {
      color: var(--v-accent-base);
      background-color: var(--v-accent-lighten2);
      border-bottom-color: transparent !important;
      .content {
        line-height: 22px;
        ul {
          padding-left: 0 !important;
        }
        li {
          list-style: none !important;
        }
      }
    }
    .v-tabs-slider-wrapper {
      color: transparent !important;
    }
    .v-tab--active {
      background-color: var(--v-accent-lighten1);
    }
  }
  .v-tabs-items {
    background: white;
    padding: 20px;
    .nutritional-info,
    .ingredients,
    .description,
    .producer_info,
    .nutritional-values,
    .other {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  @media #{map-get($display-breakpoints, 'md-and-down')} {
    .v-expansion-panel {
      margin-bottom: 2px;
      .v-expansion-panel-header {
        background: var(--v-accent-lighten1);
        color: var(--v-accent-base);
      }
    }
    .v-expansion-panel--active {
      .v-expansion-panel-header--active {
        min-height: 48px;
      }
    }
    .v-expansion-panel-content {
      .v-expansion-panel-content__wrap {
        padding: 0 6px 16px !important;
      }
    }
  }
}
</style>
